import { SquarePlan } from "./squarePlan";

export class SquareItem implements SquarePlan {
	uid: string = "";
	type: string = "plan"; //plan or product
	endUser: string = ""; //rider or owner
	id: string = "";
	active: boolean = true;
	priceId: string = "";
	name: string = "";
	serviceType: string = "";
	priceAmount: number = 0;
	imageUrl: string = "";
	description: string = "";
	category: string = "";
	minAmount: number = 0;
	maxAmount: number = 0;
	restriction: string = "";
	isTest: boolean = false;
	quantity: number = 1;
	isTrialRunning: boolean = false;
	trialLengthDays: number = 0;
	isAvailable: boolean = false;
	isViewable: boolean = false;
	//plan
	planId: string = "";
	moneyAmount: number = 0;
	planUnlockedItems: string[] = [];

	constructor() {}

	buildModel(squareProduct: SquareItem) {
		for (var key in squareProduct) {
			if (squareProduct.hasOwnProperty(key)) {
				this[key] = squareProduct[key];
			}
		}
		return this;
	}

	buildObj(squareProduct: SquareItem) {
		for (var key in squareProduct) {
			if (squareProduct.hasOwnProperty(key)) {
				this[key] = squareProduct[key];
			}
		}
		return this.toObj();
	}

	toSquarePlan(): SquarePlan {
		return {
			planId: this.planId,
			moneyAmount: this.moneyAmount,
		};
	}

	toObj() {
		return {
			uid: this.uid,
			type: this.type,
			endUser: this.endUser,
			id: this.id,
			active: this.active,
			priceId: this.priceId,
			name: this.name,
			serviceType: this.serviceType,
			priceAmount: this.priceAmount,
			imageUrl: this.imageUrl,
			description: this.description,
			category: this.category,
			minAmount: this.minAmount,
			maxAmount: this.maxAmount,
			restriction: this.restriction,
			isTest: this.isTest,
			quantity: this.quantity,
			planId: this.planId,
			moneyAmount: this.moneyAmount,
			planUnlockedItems: this.planUnlockedItems,
			isTrialRunning: this.isTrialRunning,
			trialLengthDays: this.trialLengthDays,
			isAvailable: this.isAvailable,
			isViewable: this.isViewable,
		};
	}
}
