import { CommonModule } from "@angular/common";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { IonicModule, ToastController } from "@ionic/angular";
import { Component, forwardRef, Inject } from "@angular/core";
import { ActivatedRoute, Router, RouterEvent } from "@angular/router";
import {
	MenuController,
	ModalController,
	NavController,
	Platform,
} from "@ionic/angular";
import { filter } from "rxjs/operators";
import { ErrorService } from "./core/services/error.service";
import { ModalService } from "./core/services/modal.service";
import { NavigationService } from "./core/services/navigation.service";
import { UserService } from "./core/services/user.service";
import { SystemLogsheetService } from "./core/services/systemLogsheet.service";
import { WindowService } from "./core/services/window.service";
import { HomeButtonComponent } from "./shared/components/home-button/home-button.component";
import { MenuService } from "./core/services/menu.service";
import { SwUpdate } from "@angular/service-worker";
interface MenuItems {
	displayName: string;
	page: string;
	icon: string;
}
@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
	standalone: true,
	imports: [
		IonicModule,
		RouterLink,
		RouterLinkActive,
		CommonModule,
		HomeButtonComponent,
	],
})
export class AppComponent {
	menuItems: Array<MenuItems> = [];
	public selectedItem: string;
	public dark: boolean;
	public ownersExpanded: boolean = false;
	public ridersExpanded: boolean = false;
	constructor(
		public platform: Platform,
		@Inject(forwardRef(() => UserService)) public userService: UserService,
		public navCtrl: NavController,
		public route: ActivatedRoute,
		public modalCtrl: ModalController,
		@Inject(forwardRef(() => NavigationService))
		public navService: NavigationService,
		@Inject(forwardRef(() => ModalService)) public modalService: ModalService,
		public router: Router,
		public errorService: ErrorService,
		@Inject(forwardRef(() => SystemLogsheetService))
		public systemLogsheetService: SystemLogsheetService,
		public menu: MenuController,
		public windowService: WindowService,
		private swUpdate: SwUpdate,
		private toastCtrl: ToastController,
		public menuService: MenuService
	) {
		this.initializeApp();
		this.selectedItem = "home";
		this.dark = true;
	}

	initializeApp() {
		let refreshing;
		let that = this;
		this.windowService.determineWindowSize();

		this.platform.ready().then(() => {
			let deferredPrompt;

			window.addEventListener("beforeinstallprompt", async (e) => {
				// Prevent the mini-infobar from appearing on mobile
				e.preventDefault();
				// Stash the event so it can be triggered later.
				deferredPrompt = e;
				// Update UI notify the user they can install the PWA
				// alert(`"INSTALL AVAILABLE":${deferredPrompt}`);
				const toast = await this.toastCtrl.create({
					message: "Install App?",
					position: "bottom",
					buttons: [
						{
							text: "Install",
							role: "info",
							handler: () => {
								if (deferredPrompt) {
									// Show the prompt
									deferredPrompt.prompt();
									// Wait for the user to respond to the prompt
									deferredPrompt.userChoice.then((choiceResult) => {
										if (choiceResult.outcome === "accepted") {
											console.log("User accepted the install prompt");
										} else {
											console.log("User dismissed the install prompt");
										}
										// Clear the deferredPrompt so it can be garbage collected
										deferredPrompt = null;
										// Hide the install button
									});
								}
							},
						},
						{
							text: "Dismiss",
							role: "cancel",
							handler: () => {
								console.log("Dismiss clicked");
							},
						},
					],
					duration: 10000,
				});

				await toast.present();

				toast
					.onDidDismiss()
					// .then(() => this.swUpdate.activateUpdate())
					.then(() => {
						// if (deferredPrompt) {
						// 	// Show the prompt
						// 	deferredPrompt.prompt();
						// 	// Wait for the user to respond to the prompt
						// 	deferredPrompt.userChoice.then((choiceResult) => {
						// 		if (choiceResult.outcome === "accepted") {
						// 			console.log("User accepted the install prompt");
						// 		} else {
						// 			console.log("User dismissed the install prompt");
						// 		}
						// 		// Clear the deferredPrompt so it can be garbage collected
						// 		deferredPrompt = null;
						// 		// Hide the install button
						// 	});
						// }
					});
				// Optionally, send analytics event that PWA install promo was shown.
				console.log(`'beforeinstallprompt' event was fired.`);
			});
			this.swUpdate.available.subscribe(async (res) => {
				const toast = await this.toastCtrl.create({
					message: "Update available!",
					position: "bottom",
					buttons: [
						{
							role: "cancel",
							text: "Reload",
						},
					],
				});

				await toast.present();

				toast
					.onDidDismiss()
					// .then(() => this.swUpdate.activateUpdate())
					.then(() => window.location.reload());
			});
			navigator.serviceWorker.addEventListener("controllerchange", function () {
				if (refreshing) return;
				window.location.reload();
				refreshing = true;
			});

			window.addEventListener("unhandledrejection", function (event) {
				console.warn(
					"WARNING: Unhandled promise rejection. Shame on you! Reason: " +
						event.reason
				);
				that.errorService.handleError(event.reason);
			});

			document.documentElement.addEventListener(
				"gesturestart",
				function (event) {
					console.log("PREVENTING ZOOM");
					event.preventDefault();
				},
				false
			);

			this.router.events
				.pipe(filter((e) => e instanceof RouterEvent))
				.subscribe((e) => {
					let event = e as RouterEvent;
					let end =
						event.url.indexOf(";") == -1
							? event.url.length
							: event.url.indexOf(";");
					let pageStr = event.url.substring(event.url.indexOf("/") + 1, end);
					if (pageStr) {
						this.selectedItem = pageStr;
					}
				});
		});

		this.menuItems.push({
			displayName: "Rider",
			page: "home",
			icon: "home",
		});
	}

	toggleSubMenu(): void {
		this.ownersExpanded = !this.ownersExpanded;
	}

	toggleRiderSubMenu(): void {
		this.ridersExpanded = !this.ridersExpanded;
	}
}
