<ion-app>
	<ion-menu
		contentId="main-content"
		menuId="mainMenu"
		side="end"
		swipeGesture="false"
		type="overlay"
	>
		<ion-list>
			<ion-menu-toggle auto-hide="true">
				<ion-header>
					<ion-toolbar color="primary">
						<ion-title>Menu</ion-title>
					</ion-toolbar>
				</ion-header>
			</ion-menu-toggle>
			<!-- <ion-menu-toggle auto-hide="true">
				<ion-item (click)="menuService.navigateTo('')" color="primary">
					<ion-label> Rider </ion-label>
				</ion-item>
			</ion-menu-toggle> -->
			<ion-menu-toggle
				auto-hide="true"
				*ngFor="let item of menuService.menuItems()"
			>
				<ion-item
					(click)="
						item.navOnHome
							? menuService.scroll(item.page)
							: menuService.navigateTo(item.page)
					"
					color="primary"
				>
					<ion-label>
						{{ item.displayName }}
					</ion-label>
					<ion-icon
						style="font-size: 28px"
						color="tertiary"
						(click)="toggleSubMenu(); $event.stopPropagation()"
						[name]="ownersExpanded ? 'chevron-up' : 'chevron-down'"
						slot="end"
						*ngIf="item.page === 'owner'"
					></ion-icon>
					<ion-icon
						style="font-size: 28px"
						color="tertiary"
						(click)="toggleRiderSubMenu(); $event.stopPropagation()"
						[name]="ridersExpanded ? 'chevron-up' : 'chevron-down'"
						slot="end"
						*ngIf="item.displayName === 'Rider'"
					></ion-icon>
				</ion-item>

				<ion-list *ngIf="item.page === 'owner' && ownersExpanded">
					<ion-menu-toggle
						auto-hide="true"
						*ngFor="let child of menuService.ownersMenu()"
					>
						<ion-item (click)="menuService.navigateTo(child.page)">
							<ion-label>
								{{ child.displayName }}
							</ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>
				<ion-list *ngIf="item.displayName === 'Rider' && ridersExpanded">
					<ion-menu-toggle
						auto-hide="true"
						*ngFor="let child of menuService.ridersMenu()"
					>
						<ion-item (click)="menuService.navigateTo(child.page)">
							<ion-label>
								{{ child.displayName }}
							</ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>
			</ion-menu-toggle>
		</ion-list>
	</ion-menu>

	<ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
