export const environment = {
	production: true,
	cloudFunctionHost: "us-central1-flagger-ai",
	firebaseConfig: {
		apiKey: "AIzaSyBTiKggfjemkv6Zm3MGzI3I7au4VhKKVg0",
		authDomain: "flagger-ai.firebaseapp.com",
		projectId: "flagger-ai",
		storageBucket: "flagger-ai.appspot.com",
		messagingSenderId: "561618635233",
		appId: "1:561618635233:web:1151d2488376aac8d4eaa0",
		measurementId: "G-Y439TG4WMX",
	},
	recaptcha: {
		siteKey: "6LdwzhYnAAAAACJkPZtWZiCiFKfSLellG4MS-t5x",
	},
};
