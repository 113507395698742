export function estimateRemainingTime(array, currentIndex, startTime) {
	const elapsedTime = Date.now() - startTime;

	const averageTimePerElement = elapsedTime / (currentIndex + 1);

	const remainingElements = array.length - currentIndex - 1;

	const estimatedRemainingTime = averageTimePerElement * remainingElements;

	console.log(
		`Progress - ${((currentIndex / array.length) * 100).toFixed(
			2
		)} %. Estimated remaining time: ${(estimatedRemainingTime / 60000).toFixed(
			0
		)} minutes. Index ${currentIndex}`
	);
}
