import { BreakpointState } from "@angular/cdk/layout";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class WindowService {
	public mobileView: boolean = false;
	public mobilePhone: boolean = false;
	public sizeChanged$: Subject<boolean> = new Subject();
	constructor(public breakpointObserver: BreakpointObserver) {}
	public determineWindowSize() {
		this.breakpointObserver
			.observe(["(min-width: 450px)"])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.mobileView = false;
					this.sizeChanged$.next(true);
				} else {
					this.mobileView = true;
					if (!this.mobilePhone) {
						this.mobilePhone = true;
					}
					this.sizeChanged$.next(true);
				}
			});
	}
}
